import axios from "axios";

const api = axios.create({
  baseURL: "https://diligent-nourishment-production.up.railway.app/api",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

// api.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("Error");
    }
    return Promise.reject(error);
  }
);

export default api;
